import { FeatureData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import params from '../../../core/sysext/Boutique/containers/Produit/containers/Categorie/params.json';
import CMSView from '../../../core/sysext/CMS/components/View';
import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';
import Form from '../../../core/sysext/CMS/containers/Boutique/components/new/Form';

const PageCategoriesUpdate: FC<PageProps & { id: string }> = props => {
  const { id, location } = props;

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  const model = new FeatureData(boutique, {
    params,
    collectionName: 'productCategories',
  });

  if (!id) {
    return <div>La catégorie n&apos;existe pas ou a été supprimé</div>;
  }

  return (
    <Backoffice location={location}>
      <CMSView>
        <Form
          {...props}
          docId={id}
          itemPathnamePrefix="/boutique/categories/"
          model={model}
          name="category"
          type="update"
        />
      </CMSView>
    </Backoffice>
  );
};

export default PageCategoriesUpdate;
